import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Axios from "axios";
import toastr from "toastr";
import 'toastr/build/toastr.css';
const Wishlistpage = () => {
  const location = useLocation();
  const [allframes, setAllframes] = useState([]);
  const [framecount, setFramecount] = useState([]);

  const scrollToHash = useCallback(() => {
    const hash = location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [location.hash]);

  useEffect(() => {
    scrollToHash();
  }, [scrollToHash]);

  // favourite api
  useEffect(() => {
    favouriteframes();
  }, []);

  function favouriteframes() {
    const url =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFavouriteFrames";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(url, config)
        .then((res) => {
          setAllframes(res?.data);
          setFramecount(res?.data.length);
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }

  // delete favorites api
  const favoritedata = (frameId) => {
    ;
    const authToken = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const body = {
      userId: userId,
      frameId: frameId,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/addfavourite";

    // Retrieve the token from local storage

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          
          favouriteframes();
          toastr.success(res.data.message)
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };

  return (
    <>
      <div className="wishlist-content">
        <div className="wishlist-top">
          <div className="row">
            <div className="col-md-12">
              <div className="wishlist-inside">
                <div className="container">
                  <h3 className="page-title">
                    My Wishlist
                    <span className="page-titlespan">{framecount} items </span>
                  </h3>
                  <div className="row">
                    {allframes.length === 0 ? (
                      <p className="no-itemsdata">No items</p>
                    ) : (
                      allframes.map((value, index) => (
                        <div className="col-md-2" key={index}>
                          <div className="card-contents-inside">
                            <NavLink
                              className="delete-btn"
                              onClick={() => favoritedata(value.frameId)}
                            >
                              <i className="fas fa-times"></i>
                            </NavLink>

                            <div className="card card-inside">
                              <img
                                className="card-images"
                                src={value?.image}
                                alt="no-data"
                              />
                            </div>
                            <div className="card-body card-body-inside">
                              <p className="card-title insidecard-balajititle">
                                {value.title}
                              </p>
                              <p className="card-text insidecard-balajipara">
                                {value.description}
                              </p>
                              <div className="money-bagbtn">
                                <h5 className="price-card">₹{value.price}</h5>
                                <NavLink className="addto-bag">
                                  Add to Bag
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wishlistpage;
