import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";

const Pigmentprinting = () => {
  const sectionRef = useRef(null);
  const [allcategoryList, setAllcategorylist] = useState([]);
  const [framecount, setFramecount] = useState([]);
  const [getFliters, setFilters] = useState([]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  useEffect(() => {
    getFeatureList()
  }, [])

  useEffect(() => {
    let selected = localStorage.getItem("PRINTING");
    if (!selected) {
      selected = "PRINTING";
    }
    let data = {
      categories: selected,
    };
    filterData(data);
  }, []);
  function filterData(data) {
    const body = {
      filterId: data.categories,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/filterById";
    Axios.post(apiUrl, body)
      .then((res) => {
        setAllcategorylist(res?.data);
        setFramecount(res?.data.length);
      })
      .catch((err) => {
        console.error("Error contact us ", err);
      });
  }


  // add to bag post api
  const getFeatureList = (data) => {

    const authToken = localStorage.getItem("token");
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFrameByTitle";

    // Retrieve the token from local storage

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(apiUrl, config)
        .then((res) => {
          
          setFilters(res.data)
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };



  return (
    <>
      <div className="pigmentprinting-content">
        <div className="pigmentprinting-main">
          <div className="container">
            <div className="row">
              <h3 className="page-title">
                <NavLink className="page-titlespan" to="/landing">
                  {" "}
                  Home /{" "}
                </NavLink>{" "}
                PRINTING
              </h3>
              <div className="col-md-3">
                <div className="left-filter-printing">
                  <h1 className="left-head">Collections</h1>
                  {getFliters.map((item, index) => (
        <div key={index} className="form-check">
          <input
            className="form-check-input radio-check-cusr"
            type="checkbox"
            defaultValue=""
            id={`flexCheckChecked_${index}`}
          />
          <label className="form-check-label radio-label" htmlFor={`flexCheckChecked_${index}`}>
            {item} {/* Assuming your data has a label property */}
          </label>
        </div>
      ))}

                </div>
              </div>
              <div className="col-md-9">
                {/* <ul className="filter-top">
              <li>Filter</li>
              <li className="total-products">{framecount} products</li>
              <li>Relevance</li>
            </ul> */}
                <div className="pigmentlists-data">

                  <div className="row">
                    {allcategoryList.length === 0 ? (
                      <p className="no-items">No items are available</p>
                    ) : (
                      allcategoryList.map((frames, index) => (
                        <div className="col-md-3" key={index}>
                          <div className="card-box">
                            <NavLink
                              className="card-contents-inside"
                              to={`/card-inner/${frames._id}`}
                            >
                              <div className="card card-inside">
                                <img
                                  className="card-images"
                                  src={`data:image/jpeg;base64,${frames.image}`}
                                  alt="no-data"
                                />
                              </div>
                              <div className="card-body card-body-inside">
                                <p className="card-title insidecard-balajititle">
                                  {frames.title}
                                </p>
                                {/* <p className="card-text insidecard-balajipara">
                            {frames.description}
                          </p> */}
                                {/* <h5 className="price-card">₹ {frames.price}</h5> */}
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Pigmentprinting;
