import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
const Header = (props) => {
  if (props.datas !== 'Calls') {
    // cartitems()
  }
  const buttonRef = useRef();
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState(""); // Default country code
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [navMenuActive, setNavMenuActive] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);
  const [profilenumber, setprofilenumber] = useState(null);
  const [cartItemsdata, setCartItemsdata] = useState([]);
  const [cartframecount, setCartframecount] = useState(0);

  const closeNavRef = useRef();

  // without login data open
  useEffect(() => {
    const handleNavItemClick = (event) => {
      const target = event.currentTarget;

      const parentDropdown = target.closest(".dropdown-menu");

      if (!parentDropdown) {
        setNavMenuActive(false); // Hide mobile navigation menu
      }
    };

    function payWithRazorPay() {
      let razorPay = 'rzp_test_HQ1BFU82GWWz16'
      const options = {
        key: razorPay,
        amount: 300, // amount should be in paise format to display Rs 1255 without decimal point
        currency: 'INR',
        name: 'Wavpe', // company name or product name
        description: 'test',  // product description
        image: './../../../../assets/images/wavpe-logo.svg', // company logo or product image
        order_id: 345667, // order_id created by you in backend
        modal: {
          // We should prevent closing of the form when esc key is pressed.
          escape: false,
        },
        notes: {
          // include notes if any
        },
        theme: {
          color: '#0c238a'
        }
      };
    }

    const navLinks = document.querySelectorAll(".navbar-collapse a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavItemClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavItemClick);
      });
    };
  }, []);
  // profile number showing
  useEffect(() => {
    const storedData = localStorage.getItem("mobile");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setprofilenumber(parsedData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, []);

  // for logout
  const logOut = () => {
    localStorage.clear();
    window.location.href = "/landing";
    setAuthToken(null);
    let token = localStorage.getItem("token");

    if (token) {
      setAuthToken(token);
    } else {
    }
  };

  // for token set in header
  useEffect(() => {
    let token = localStorage.getItem("token");

    if (token) {
      setAuthToken(token);
    } else {
    }
  });
  const handleHamburgerClick = () => {
    setNavMenuActive(!navMenuActive);
  };

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector(".navbar");
      if (window.scrollY > 100) {
        nav.classList.add("blackto");
      } else {
        nav.classList.remove("blackto");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);
  const openModal = () => {
    const modal = new window.bootstrap.Modal(
      document.getElementById("loginmodal")
    );
    modal.show();
  };

  const handleSendOtpClick = () => {
    setShowOtpForm(true);
  };

  // login api integration

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "countryCode") {
      setCountryCode(value);
    } else if (name === "phoneNumber") {
      setPhoneNumber(value);
    } else if (name === "otp") {
      setOtp(value);
    }
  };
  const handleButtonClick = (param) => {
    // Assuming '/tanjaour/TANJORE PAINTINGS' is your route
   setTimeout(()=>{
    localStorage.setItem('routeChange',param)
   },0)
  };
  // for country code
  useEffect(() => {
    Axios.get("https://aanvikshikiartapi.aanvikshiki.art/api/user/getCountries")
      .then((res) => {
        setCountryCodeOptions(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch country code options:", err);
      });
  }, []);

  const sendOtp = (e) => {
    e.preventDefault();

    const body = {
      countryCode: countryCode,
      mobile: phoneNumber,
    };

    Axios.post(
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/loginm",
      body
    )
      .then((res) => {
        setOtpSent(true);
        handleSendOtpClick();
      })
      .catch((err) => {
        setError("Failed to send OTP. Please try again.");
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    const body = {
      countryCode: countryCode,
      mobile: phoneNumber,
      otp: otp,
    };

    Axios.post(
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/validateLoginUser",
      body
    )
      .then((res) => {
        setError("");
        localStorage.setItem("token", res.data?.token);
        localStorage.setItem("userId", res.data?.id);
        if (closeNavRef.current) {
          closeNavRef.current.click();
        }
        localStorage.setItem("mobile", res.data?.mobile);
        if (res.data?.token) {
          setAuthToken(res.data?.token);
          if (buttonRef.current) {
            buttonRef.current.click(); // Trigger the button's click event for model close
          }
        } else {
        }
        // Handle successful login, e.g., set user's session
      })
      .catch((err) => {
        setError("Invalid OTP. Please try again.");
      });
  };

  // if token have in local menu pages works

  function goToWishlist() {
    let token = localStorage.getItem("token");
    if (token) {
      window.location.href = "/wishlist";
    }
  }

  function goToBag() {
    let token = localStorage.getItem("token");
    if (token) {
      window.location.href = "/bag";
    }
  }
  useEffect(() => {
    cartitems();
  }, [])
  // cart items
  function cartitems() {
    const apiUrl = "https://aanvikshikiartapi.aanvikshiki.art/api/user/getCart";
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };
      Axios.get(apiUrl, config)
        .then((res) => {
          setCartItemsdata(res.data);
          setCartframecount(res?.data.length);
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }

  return (
    <>
      <header className="container">
        <nav
          className={`navbar navbar-expand-lg navbar-light TopnavBar-Section`}
        >
          <div id="progress-barss"></div>
          <div className="container">
            <NavLink className="navbar-brand logo-section" to="/landing">
              <img
                className="main-logo"
                src="/images/navbar/logo.png"
                loading="eager"
                alt="anvikshiki-Logo"
              />
            </NavLink>
            <form className="form-inline d-block d-lg-none">
              <ul className="navbar edit-nav-mob p-2">
                <li className="nav-item ">
                  <NavLink
                    className="nav-link navlink-mainlist navlink-mainlist11"
                    // to="/bag"
                    onClick={goToBag}
                  >
                    <i class="fas fa-shopping-bag"></i>
                    <div className="cart-items">{cartframecount}</div>
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    // to={"/wishlist"}
                    className="nav-link navlink-mainlist navlink-mainlist1"
                    onClick={goToWishlist}
                  >
                    <i class="fas fa-heart"></i>
                  </NavLink>
                </li>

                {authToken ? (
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link navlink-mainlist navlink-mainlist1 dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-user icondata"></i>
                    </div>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="profile-detilas">
                        <p className="profile-name">Hello Aanvikshiki ARTS</p>
                        <p className="profile-phone">{profilenumber?.mobile}</p>
                      </div>
                      <NavLink className="dropdown-item" to="/Settings">
                        My Profile
                      </NavLink>
                      <NavLink className="dropdown-item" to="/myorder">
                        My Orders
                      </NavLink>

                      <NavLink className="dropdown-item1" onClick={logOut}>
                        Log Out
                      </NavLink>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link login-header"
                      data-toggle="modal"
                      data-target="#loginmodal"
                      // onClick={openModal}
                      type="button"
                    >
                      Login
                    </NavLink>
                  </li>
                )}
              </ul>
            </form>

            <div
              className={`hamburger-edit ${navMenuActive ? "active" : ""}`}
              onClick={handleHamburgerClick}
            >
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </div>
            <div
              className={`navbar-collapse navbarTop-colapse ${navMenuActive ? "active" : ""
                }`}
            >
              <ul className="navbar-nav mr-auto navbarTopUl-edit p-2">
                <li className="nav-item">
                  <NavLink
                    className="nav-link navlink-mainlist111"
                    to={`/frames/PRINTING`}
                  >
                    PRINTING
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    className="nav-link navlink-mainlist111"
                    to={`/frames/TANJORE PAINTINGS`}
                  >
                    TANJORE PAINTING
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    className="nav-link navlink-mainlist111"
                    to={`/PoojaRoom`}
                  >
                    POOJA ROOM 
                  </NavLink>
                </li>
           
              </ul>
              <form className="form-inline d-none d-lg-block">
                <ul className="navbar-nav mr-auto navbarTopUl-edit p-2">
                  <li className="nav-item ">
                    <NavLink
                      className="nav-link navlink-mainlist navlink-mainlist11"
                      // to="/bag"
                      onClick={goToBag}
                    >
                      <i class="fas fa-shopping-bag"></i>
                      <div className="cart-items">{cartframecount}</div>
                    </NavLink>
                  </li>
                  <li className="nav-item ">
                    <NavLink
                      // to={"/wishlist"}
                      className="nav-link navlink-mainlist navlink-mainlist1"
                      onClick={goToWishlist}
                    >
                      <i class="fas fa-heart"></i>
                    </NavLink>
                  </li>
                
                  {authToken ? (
                    <li className="nav-item dropdown">
                      <div
                        className="nav-link navlink-mainlist navlink-mainlist1 dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fas fa-user icondata"></i>
                      </div>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <div className="profile-detilas">
                          <p className="profile-name">Hello Aanvikshiki ARTS</p>
                          <p className="profile-phone">{profilenumber?.mobile}</p>
                        </div>
                        <NavLink className="dropdown-item" to="/Settings">
                          My Profile
                        </NavLink>
                        <NavLink className="dropdown-item" to="/myorder">
                          My Orders
                        </NavLink>

                        <NavLink className="dropdown-item1" onClick={logOut}>
                          Log Out
                        </NavLink>
                      </div>
                    </li>
                  ) : (
                    <li className="nav-item ">
                      <NavLink
                        className="nav-link login-header"
                        data-toggle="modal"
                        data-target="#loginmodal"
                        // onClick={openModal}
                        type="button"
                      >
                        Login
                      </NavLink>
                    </li>
                  )}
                </ul>
              </form>
            </div>
          </div>
        </nav>
      </header>
      {/* Modal */}

      {/* login modal */}
      <div
        className="modal login-modals fade"
        id="loginmodal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg login-content">
          <div className="modal-content">
            <div className="modal-body login-inside-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="login-left">
                    <img
                      className="left-image w-100"
                      src="/images/navbar/Ganesh1.jpg"
                      alt="no-data"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="login-right-content">
                    <button
                      ref={buttonRef}
                      // ref={closeNavRef}
                    
                      type="button"
                      className="close login-closebtn"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>

                    <div className="login-mainform">
                      {showOtpForm ? (
                        <form className="login-form" onSubmit={verifyOtp}>
                          <p  className="login-title">Login</p>
                          <label className="lable-contact">Enter OTP</label>
                          <div className="input-group email-FormInside mb-3">
                            <input
                              type="tel"
                              className="form-control phoneInput-number phoneInput-number1"
                              id="phoneNumber"
                              placeholder="123456"
                              onChange={handleChange}
                              name="otp"
                            />
                          </div>
                          <div className="form-group email-FormInside">
                            <button
                              type="button"
                              className="btn LoginBtn-bottom"
                              onClick={verifyOtp}
                            >
                              Login
                            </button>
                          </div>
                          <div className="form-group email-FormInside">
                            <NavLink className="btn btn-primary google-linkBtn">
                              <span className="google-icon">
                                <i className="fab fa-google" />
                              </span>
                              Continue with Google
                            </NavLink>
                          </div>
                        </form>
                      ) : (
                        <form className="login-form" onSubmit={sendOtp}>
                          <p className="login-title">Login</p>
                          <label className="lable-contact">
                            Enter your Contact No.
                          </label>
                          <div className="input-group email-FormInside mb-3">
                            <div className="input-group-prepend topPhone-inputGroup">
                              <select
                                className="custom-select phoneSelect-formInside"
                                name="countryCode"
                                value={countryCode}
                                onChange={handleChange}
                              >
                                <option value="" disabled>
                                  Select Counrty
                                </option>
                                {countryCodeOptions.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.label} {option.callingCode}
                                    {/* (
                                    {option.code}
                                    ) */}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input
                              type="tel"
                              className="form-control phoneInput-number"
                              id="phoneNumber"
                              placeholder="Enter your phone number"
                              onChange={handleChange}
                              name="phoneNumber"
                            />
                          </div>
                          <div className="form-group email-FormInside">
                            <button
                              type="button"
                              onClick={sendOtp}
                              className="btn btn-primary LoginBtn-bottom"
                            >
                             Continue
                            </button>
                          </div>
                          <div className="form-group email-FormInside">
                            <NavLink className="btn btn-primary google-linkBtn">
                              <span className="google-icon">
                                <i className="fab fa-google" />
                              </span>
                              Continue with Google
                            </NavLink>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
