import React, { useEffect, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import Header from "../Header/Header";
import axios from "axios";
import toastr from "toastr";
import 'toastr/build/toastr.css';

const Addtobag = () => {
  const [cartItemsdata, setCartItemsdata] = useState([]);
  const [cartframecount, setCartframecount] = useState(0);
  const [selectAddresId, setSelectAddresId] = useState(0);

  const [name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [pincode, setpincode] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPrice2, setTotalPrice2] = useState("");
  const [isAddress, setIsAddress] = useState(Boolean);
  const data = "Hello from the parent";
  const navigate = useNavigate();
  const [addressData, setAddressData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    pincode: "",
    address: "",
    area: "",
    city: "",
    state: "",
    isDefault: false,
  });


  // Function to close the navigation
  const closeNav = () => {
    // Check if the ref is defined before attempting to click
    if (closeNavRef.current) {
      closeNavRef.current.click();
    }
  };



  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddNewAddress, setisAddNewAddress] = useState(false);
  const [isEditModeDate, setisEditModeData] = useState(false);
  const [removeaddress, setRemoveaddress] = useState("");
  const [removeitems, setRemoveitems] = useState("");
  const [authToken, setAuthToken] = useState(null);
  const closeNavRef = useRef(null);

  useEffect(() => {
    cartitems();
    getDelivaryaddress();
  }, []);

  function upDateCategory(id, eve) {
    console.log(id, "", eve);
    let amount =0
    
    cartItemsdata.forEach((value)=>{
      if(value._id === id){
        value.quantity = eve
      }
    })
    cartItemsdata.forEach((element)=>{
      
      element.finalPrice  = element.quantity * element.frames.price
      amount+= element.finalPrice
    })
    console.log(cartItemsdata)
    console.log('selected Dropdown amount',amount)
    setTotalPrice(amount)
  }

  function selectQuantity(data) {

    console.log(data)
    //   
    //  let originalPrice = totalPrice2
    //  setTotalPrice(originalPrice)
    // const selectedValue = parseInt(event.target.value)
    //  let finalPrc=    originalPrice * selectedValue
    //  console.log(finalPrc)
    //   setTotalPrice(finalPrc)
    //   setQuantity(event.target.value)
  }

  function cartitems() {
    const apiUrl = "https://aanvikshikiartapi.aanvikshiki.art/api/user/getCart";
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };
      Axios.get(apiUrl, config)
        .then((res) => {
          
          let totalPrice = 0
          let amount =0
          res.data.forEach((value)=>{
            value.finalPrice  =value.quantity * value.frames.price
            amount+= value.finalPrice
          })
          setCartItemsdata(res.data);
          setCartframecount(res?.data.length);
          res.data.forEach(element => {
            // totalPrice += element.frames.price
            totalPrice += element.price
          });

          setTotalPrice(totalPrice)
          setTotalPrice2(totalPrice)
          console.log(res.data)
          console.log('total cost',amount)
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }

  // address apis
  const editAddress = (objectData) => {
    console.log(objectData);
    setisEditModeData(true);
    setFormData({
      id: objectData._id,
      name: objectData.name || "",
      mobile: objectData.mobile || "",
      pincode: objectData.pincode || "",
      address: objectData.address || "",
      area: objectData.area || "",
      city: objectData.city || "",
      state: objectData.state || "",
      isDefault: false, // You may need to set this based on your logic
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "phoneNumber") {
      setphoneNumber(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "state") {
      setState(value);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "area") {
      setArea(value);
    } else if (name === "pincode") {
      setpincode(value);
    }
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addAddress = (e) => {
    ;
    e.preventDefault();

    const body = {
      name: name,
      mobile: phoneNumber,
      address: address,
      state: state,
      city: city,
      area: area,
      pincode: pincode,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/orderDeliveryAddress";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          
          toastr.success(res.data.message)
          setisAddNewAddress(false);
          getDelivaryaddress();
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };

  function updateAddress() {
    console.log(formData);
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/updateAddress";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, formData, config)
        .then((res) => {
          console.log(res);
          setisEditModeData(false);
          getDelivaryaddress();
                  toastr.success(res.data.message)
        })
        .catch((err) => {
          console.error("Error updating address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }
  function cancleupdateaddress() {
    setisEditModeData(false);
  }

  function addNewAddress() {
    ;
    setisAddNewAddress(true);
  }
  function cancleaddaddress() {
    setisAddNewAddress(false);
  }

  // show address api
  function getDelivaryaddress() {
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/getDeliveryAddress";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(apiUrl, config)
        .then((res) => {
          setAddressData(res?.data.address);
          console.log(res?.data.address);
         
          if (res?.data.address.length <= 0) {
            setIsAddress(false);
          } else {
            setIsAddress(true);
          }
          // Handle the response data as needed
        })
        .catch((err) => {
          console.error("Error fetching data ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }
  // remove address api
  const removeAddress = (data) => {
    const userId = localStorage.getItem("userId");

    const body = {
      userId: userId,
      id: data._id,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/deleteAddress";
    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          setRemoveaddress(res);
          getDelivaryaddress();
          toastr.success(res.data.message)
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };
  const selectAdress = (data) => {
    console.log(data)
    setSelectAddresId(data._id)
  }

  // remove item in cart
  const removeItems = (data) => {
    const userId = localStorage.getItem("userId");

    const body = {
      userId: userId,
      id: data._id,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/deleteCart";
    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          setRemoveitems(res);
          toastr.success(res.data.message)
          cartitems();
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };


  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    let authToken = localStorage.getItem('token')

    // creating a new order
    let userId = localStorage.getItem('userId')
    let requiredData = {
      price: totalPrice,
      userId: userId,
      addressId: selectAddresId,
      allItems: cartItemsdata,
      quantity: quantity
    }
    const result = await axios.post("https://aanvikshikiartapi.aanvikshiki.art/api/Payment/orders", requiredData, {
      headers: {
        'authorization': authToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;
    let reciptNumber = result.data.receipt
    const options = {
      key: "rzp_test_HQ1BFU82GWWz16", // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Aanvikshiki Payments.",
      description: "Test Transaction",
      image: '',
      order_id: order_id,
      handler: async function (response) {
        
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          reciptNumber: reciptNumber,
          cartItemsdata: cartItemsdata
        };
        
        const result = await axios.post("https://aanvikshikiartapi.aanvikshiki.art/api/Payment/success", data, {
          headers: {
            'authorization': authToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        if (result.data.msg === 'success') {
          navigate("/myorder");
        }
      },
      prefill: {
        name: "Aanvikshiki Payments",
        email: "SoumyaDey@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Soumya Dey Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  return (
    <>
      <div className="addbaglist-content">
        <div className="container">
          <div className="anviski-MultiStep-form">
            <div className="form-top m-auto">
              <ul
                class="nav nav-tabs multi-stepForm-below"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item form-toplists" role="presentation">
                  <button
                    class="nav-link form-TopBtns active"
                    id="description-tab"
                    data-toggle="tab"
                    data-target="#description"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    BAG
                  </button>
                </li>
                <li class="nav-item form-toplists" role="presentation">
                  <button
                  ref={closeNavRef} 
                    class="nav-link form-TopBtns"
                    id="details-tab"
                    data-toggle="tab"
                    data-target="#details"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    ADDRESS
                  </button>
                </li>
                {/* <li class="nav-item form-toplists" role="presentation">
                  <button
                    class="nav-link form-TopBtns"
                    id="shipping-tab"
                    data-toggle="tab"
                    data-target="#shipping"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    PAYMENT
                  </button>
                </li> */}
              </ul>
              <div className="linebar" />
              <div class="tab-content MainTabContents-below" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div className="bag-content-below">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="leftSide-BagContent">
                          <p className="numberOf-item">
                            {cartframecount} items
                          </p>

                          <Header datar={cartframecount} />
                          {cartItemsdata.map((value, index) => (
                            <div className="product-card" key={index}>
                              <div className="cart-imageInside">
                                <img
                                  src={value.base64Images}
                                  alt="no-data"
                                  className="product-image"
                                />
                              </div>
                              <div className="product-details">
                                <h2 className="product-title">
                                  {value.frames.title}
                                </h2>
                                <p className="pigment-print">
                                  {value.featureName}
                                </p>
                                <div className="quantity-box">
                                  <label
                                    className="quantity-edit"
                                    htmlFor="quantity-select"
                                  >
                                    Qty:
                                  </label>
                                  <select
    onChange={(e) => upDateCategory(value._id, e.target.value)}
    id="quantity-select">
    {[...Array(value.quantity).keys()].map((index) => (
        <option key={index + 1} value={index + 1}>{index + 1}</option>
    ))}
</select>
                                </div>
                                <h3 className="size-content">
                                  size:{""}
                                  <span> {value.craftsizes.size}</span>
                                </h3>
                                <div className="product-price">
                                ₹ {value.price}
                                  {/* <span className="actual-price">
                                    <s>{value.frames.price}</s>
                                  </span> */}
                                  <span className="discount-percentage">
                                    0% OFF
                                  </span>
                                </div>
                              </div>
                              <div
                                className="remove-icon"
                                onClick={() => removeItems(value)}
                              >
                                <NavLink className="remove-button">X</NavLink>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="rightSide-BagContent">
                          <div className="col-md-10 col-lg-9 col-xlg-8 m-auto p-0 p-md-auto">
                            {/* <div className="coupon-sectionBelow">
                              <h2 className="coupon-title">COUPONS</h2>
                              <div className="coupon-input">
                                <input
                                  type="text"
                                  placeholder="Enter coupon code"
                                  className="coupon-inputBox"
                                />
                                <button className="apply-btn" type="button">
                                  Apply
                                </button>
                              </div>
                            </div> */}
                            {/* <hr className="line1-edit" /> */}
                            <div className="order-summary-below">
                              <h2 className="order-title">
                                Order Summary ({cartframecount} item)
                              </h2>
                              <div className="order-summary">
                                {/* Display order summary details here */}
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Total MRP:
                                  </span>
                                  <span className="right-OrderData">
                                    ₹ {totalPrice}
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Discount on MRP:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                     ₹0
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Coupon Discount:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                  ₹0
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Convenience Fee:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                    <s>₹0 </s>
                                    <span className="free-colorChange">
                                      Free
                                    </span>
                                  </span>
                                </div>
                                <hr className="horizontal-line" />
                                <div className="order-rowInside order-total">
                                  <span className="total-amount">
                                    Total Amount:
                                  </span>
                                  <span className="total-price">₹{totalPrice}</span>
                                </div>
                              </div>
                            </div>
                            <div className="place-order-inside">
                              <button
                                className="place-order-button next-page-button"
                                id="nextButton"
                                onClick={closeNav}
                              >
                                CONTINUE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="details"
                  role="tabpanel"
                  aria-labelledby="details-tab"
                >
                  <div className="adress-content-below">
                    <div className="row">
                      <div className="col-md-6 ">
                        {/* <div className="adressHeader">
                          <h4 className="select-Delivery-adress">
                            Select Delivery Address
                          </h4>
                          <p className="default-adress">Default Adress</p>
                        </div>
                        <div className="address-card">
                          <div className="left-side">
                            <div className="selection-button">
                              <input
                                className="form-check-input checkBox-inside"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                              />
                            </div>
                            <div className="left-adress-inside">
                              <div className="topAdress-rightBtns d-flex justify-content-between">
                                <div className="name-edit">Ganesh</div>
                                <div className="right-side">
                                  <button className="action-button remove-button">
                                    Remove
                                  </button>
                                  <button className="action-button edit-button">
                                    Edit
                                  </button>
                                </div>
                              </div>
                              <div className="address">
                                Nehru place, New Delhi
                              </div>
                              <div className="address">New Delhi 205985</div>
                              <div className="mobile">
                                Mobile no.{" "}
                                <span className="number-ColorChange">
                                  {" "}
                                  78******0
                                </span>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div className="new-adress-inside">
                          <button className="new-adress-button">
                            <span className="plus-icon">
                              <i className="fas fa-plus" />
                            </span>{" "}
                            Add New Adress
                          </button>
                        </div>
                        <div className="left-address-box">
                          <div className="contact-details-box">
                            <h2 className="adress-titlees">Contact Details</h2>
                            <input
                              className="adressInput-boxes"
                              type="text"
                              placeholder="Name"
                            />
                            <input
                              className="adressInput-boxes"
                              type="tel"
                              placeholder="Contact No."
                            />
                          </div>
                          <div className="contact-details-box">
                            <h2 className="adress-titlees spacing-title">
                              Address Details
                            </h2>
                            <input
                              className="adressInput-boxes"
                              type="text"
                              placeholder="Pin Code"
                            />
                            <input
                              className="adressInput-boxes"
                              type="text"
                              placeholder="Address (House no, Building, Street, Area)"
                            />
                            <div className="row">
                              <div className="col-md-6">
                                <input
                                  className="adressInput-boxes"
                                  type="text"
                                  placeholder="Locality / Town"
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  className="adressInput-boxes"
                                  type="text"
                                  placeholder="City / District"
                                />
                              </div>
                            </div>
                            <input
                              className="adressInput-boxes"
                              type="text"
                              placeholder="State"
                            />
                          </div>
                          <div className="contact-details-box">
                            <h2 className="adress-titlees spacing-title">
                              Save Address As
                            </h2>
                            <div className="buttons-below">
                              <button className="home-button">Home</button>
                              <button className="home-button">Work</button>
                            </div>
                          </div>
                          <div className="add-adress-inside">
                            <button className="adress-button">
                              ADD ADRESS
                            </button>
                          </div>
                        </div> */}
                        <div className="settings-formdata">
                          {isEditModeDate ? (
                            <form className="add-addressform-form">
                              <h3 className="address-title">UPDATE Address</h3>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control address-input-content"
                                  placeholder="Name"
                                  onChange={handleChanges}
                                  value={formData.name}
                                  name="name"
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control address-input-content"
                                  placeholder="Contact No."
                                  onChange={handleChanges}
                                  name="mobile"
                                  value={formData.mobile}
                                />
                              </div>
                              <h3 className="address-details-title">
                                Address Details
                              </h3>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control address-input-content"
                                  placeholder="Pin Code"
                                  onChange={handleChanges}
                                  value={formData.pincode}
                                  name="pincode"
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control address-input-content"
                                  placeholder="Address (House no, Building, Street, Area)"
                                  onChange={handleChanges}
                                  value={formData.address}
                                  name="address"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control address-input-content"
                                  placeholder="Locality / Town"
                                  onChange={handleChanges}
                                  value={formData.area}
                                  name="area"
                                />
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <input
                                    type="text"
                                    className="form-control address-input-content"
                                    placeholder="City / District"
                                    onChange={handleChanges}
                                    value={formData.city}
                                    name="city"
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <input
                                    type="text"
                                    className="form-control address-input-content"
                                    placeholder="State"
                                    onChange={handleChanges}
                                    value={formData.state}
                                    name="state"
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-check">
                                  <input
                                    className="form-check-input address-input-content"
                                    type="checkbox"
                                    id="gridCheck"
                                  />
                                  <label
                                    className="form-check-label address-lable-content"
                                    htmlFor="gridCheck"
                                  >
                                    Set as default delivery address
                                  </label>
                                </div>
                              </div>
                              <NavLink
                                onClick={updateAddress}
                                className="address-btn"
                              >
                                UPDATE ADDRESS
                              </NavLink>
                              <NavLink
                                className="cancleaddress-btn"
                                onClick={cancleupdateaddress}
                              >
                                Cancle
                              </NavLink>
                            </form>
                          ) : (
                            <div>
                              <div className="settings-formdata">
                                {isAddNewAddress === false ? (
                                  <div>
                                    <ul className="accounttitle-edit">
                                      <li>
                                        {" "}
                                        <h3 className="settings-titles">
                                          Saved Delivery Addresses
                                        </h3>
                                      </li>
                                    </ul>

                                    {isAddress === true ? (
                                      addressData.map((address, index) => (
                                        <div
                                          className="main-addresscarddata"
                                          key={index}
                                        >
                                          <div className="left-side">
                                            <div className="selection-button">
                                              <input
                                                onChange={() =>
                                                  selectAdress(address)
                                                }
                                                className="form-check-input checkBox-inside"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="flexRadioDefault1"
                                              />
                                            </div>
                                            <div className="left-adress-inside">
                                              <div className="topAdress-rightBtns d-flex justify-content-between">
                                                <div className="name-edit">
                                                  {address.name}
                                                </div>
                                                <div className="right-side">
                                                  <NavLink
                                                    className="action-button remove-button"
                                                    onClick={() =>
                                                      removeAddress(address)
                                                    }
                                                  >
                                                    Remove
                                                  </NavLink>
                                                  <button
                                                    onClick={() =>
                                                      editAddress(address)
                                                    }
                                                    className="action-button edit-button"
                                                  >
                                                    Edit
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="address">
                                                {address.address}
                                              </div>
                                              <div className="address">
                                                {address.city} {""}
                                                {address.pincode}
                                              </div>
                                              <div className="mobile">
                                                Mobile no.{" "}
                                                <span className="number-ColorChange">
                                                  {address.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    ) : isAddress === false ? (
                                      <div className="donthave-address">
                                        <p className="dont-para">
                                          You currently don't have any saved
                                          delivery addresses. Add an address
                                          here to be pre-filled for quicker
                                          checkout.
                                        </p>
                                      </div>
                                    ) : null}

                                    <NavLink
                                      onClick={addNewAddress}
                                      className="add-address-btn"
                                    >
                                      Add Address
                                    </NavLink>
                                  </div>
                                ) : isAddNewAddress === true ? (
                                  <form className="add-addressform-form">
                                    <h3 className="address-title">
                                      Add Address
                                    </h3>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control address-input-content"
                                        placeholder="Name"
                                        onChange={handleChange}
                                        name="name"
                                      />
                                    </div>

                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control address-input-content"
                                        placeholder="Contact No."
                                        onChange={handleChange}
                                        name="phoneNumber"
                                      />
                                    </div>
                                    <h3 className="address-details-title">
                                      Address Details
                                    </h3>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control address-input-content"
                                        placeholder="Pin Code"
                                        onChange={handleChange}
                                        name="pincode"
                                      />
                                    </div>

                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control address-input-content"
                                        placeholder="Address (House no, Building, Street, Area)"
                                        onChange={handleChange}
                                        name="address"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control address-input-content"
                                        placeholder="Locality / Town"
                                        onChange={handleChange}
                                        name="area"
                                      />
                                    </div>
                                    <div className="form-row">
                                      <div className="form-group col-md-6">
                                        <input
                                          type="text"
                                          className="form-control address-input-content"
                                          placeholder="City / District"
                                          onChange={handleChange}
                                          name="city"
                                        />
                                      </div>
                                      <div className="form-group col-md-6">
                                        <input
                                          type="text"
                                          className="form-control address-input-content"
                                          placeholder="State"
                                          onChange={handleChange}
                                          name="state"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input address-input-content"
                                          type="checkbox"
                                          id="gridCheck"
                                        />
                                        <label
                                          className="form-check-label address-lable-content"
                                          htmlFor="gridCheck"
                                        >
                                          Set as default delivery address
                                        </label>
                                      </div>
                                    </div>
                                    <NavLink
                                      className="address-btn"
                                      onClick={addAddress}
                                    >
                                      ADD ADDRESS
                                    </NavLink>
                                    <NavLink
                                      className="cancleaddress-btn"
                                      onClick={cancleaddaddress}
                                    >
                                      Cancle
                                    </NavLink>
                                  </form>
                                ) : null}

                                {/* if you want add address show this */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="rightSide-BagContent">
                          <div className="col-md-10  ml-auto p-0 p-md-auto">
                            <div className="coupon-sectionBelow">
                              <h2 className="coupon-title">COUPONS</h2>
                              <div className="coupon-input">
                                <input
                                  type="text"
                                  placeholder="Enter coupon code"
                                  className="coupon-inputBox"
                                />
                                <button className="apply-btn" type="button">
                                  Apply
                                </button>
                              </div>
                            </div>
                            <hr className="line1-edit" />
                            <div className="order-summary-below">
                              <h2 className="order-title">
                                Order Summary (2 item)
                              </h2>
                              <div className="order-summary">
                                {/* Display order summary details here */}
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Total MRP:
                                  </span>
                                  <span className="right-OrderData">
                                    ₹ {totalPrice}
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Discount on MRP:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                    ₹0
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Coupon Discount:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                    ₹0
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Convenience Fee:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                    <s>₹0 </s>
                                    <span className="free-colorChange">
                                      Free
                                    </span>
                                  </span>
                                </div>
                                <hr className="horizontal-line" />
                                <div className="order-rowInside order-total">
                                  <span className="total-amount">
                                    Total Amount:
                                  </span>
                                  <span className="total-price">₹{totalPrice}</span>
                                </div>
                              </div>
                            </div>
                            <div className="place-order-inside">
                              <button
                                onClick={displayRazorpay}
                                className="place-order-button next-page-button"
                                id="nextButton"
                              >
                                PLACE ORDER
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  class="tab-pane fade"
                  id="shipping"
                  role="tabpanel"
                  aria-labelledby="shipping-tab"
                >
                  <div className="adress-content-below">
                    <div className="row">
                      <div className="col-md-6 m-auto">
                        <div className="rightSide-BagContent">
                          <div className="col-md-10  m-auto">
                            <div className="coupon-sectionBelow">
                              <h2 className="coupon-title">COUPONS</h2>
                              <div className="coupon-input">
                                <input
                                  type="text"
                                  placeholder="Enter coupon code"
                                  className="coupon-inputBox"
                                />
                                <button className="apply-btn" type="button">
                                  Apply
                                </button>
                              </div>
                            </div>
                            <hr className="line1-edit" />
                            <div className="order-summary-below">
                              <h2 className="order-title">
                                Order Summary (2 item)
                              </h2>
                              <div className="order-summary">
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Total MRP:
                                  </span>
                                  <span className="right-OrderData">
                                    ₹ 8500
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Discount on MRP:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                    -₹3500
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Coupon Discount:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                    -₹400
                                  </span>
                                </div>
                                <div className="order-rowInside">
                                  <span className="left-OrderData">
                                    Convenience Fee:
                                  </span>
                                  <span className="right-OrderData price-colorChange">
                                    <s>₹150 </s>
                                    <span className="free-colorChange">
                                      Free
                                    </span>
                                  </span>
                                </div>
                                <hr className="horizontal-line" />
                                <div className="order-rowInside order-total">
                                  <span className="total-amount">
                                    Total Amount:
                                  </span>
                                  <span className="total-price">₹2500</span>
                                </div>
                              </div>
                            </div>
                            <div className="place-order-inside">
                              <button
                                className="place-order-button next-page-button"
                                id="nextButton"
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addtobag;
