import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import toastr from "toastr";
import 'toastr/build/toastr.css';
const Settings = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [pincode, setpincode] = useState("");

  const [email, setEmail] = useState("");
  const [profilephone, setProfilephone] = useState("");
  const [getProfile, setGetProfile] = useState({
    email: "",
    profilephone: "",
  });
  const [isAddress, setIsAddress] = useState(Boolean);

  const [addressData, setAddressData] = useState([]);

  // const [profileData, setProfileData] = useState({
  //   email: "",
  //   profilephone: "",
  // });

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    pincode: "",
    address: "",
    area: "",
    city: "",
    state: "",
    isDefault: false,
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddNewAddress, setisAddNewAddress] = useState(false);
  const [isEditModeDate, setisEditModeData] = useState(false);
  const [removeaddress, setRemoveaddress] = useState("");
  const [authToken, setAuthToken] = useState(null);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const profileHandle = (e) => {
    const { name, value } = e.target;
    setGetProfile({
      ...getProfile,
      [name]: value,
    });
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const editAddress = (objectData) => {
    console.log(objectData);
    setisEditModeData(true);
    setFormData({
      id: objectData._id,
      name: objectData.name || "",
      mobile: objectData.mobile || "",
      pincode: objectData.pincode || "",
      address: objectData.address || "",
      area: objectData.area || "",
      city: objectData.city || "",
      state: objectData.state || "",
      isDefault: false, // You may need to set this based on your logic
    });
  };

  const saveProfile = () => {
    // Perform any save action you need here, such as sending data to a server.
    // For this example, we'll just log the data.
    console.log("Saving profile data:", getProfile);
    toggleEditMode(); // Exit edit mode after saving.
    profiledata();
  };

  // add address api

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "phoneNumber") {
      setphoneNumber(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "state") {
      setState(value);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "area") {
      setArea(value);
    } else if (name === "pincode") {
      setpincode(value);
    }
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addAddress = (e) => {
    e.preventDefault();

    const body = {
      name: name,
      mobile: phoneNumber,
      address: address,
      state: state,
      city: city,
      area: area,
      pincode: pincode,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/orderDeliveryAddress";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          console.log(res);
          setisAddNewAddress(false);
          getDelivaryaddress();
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };

  function updateAddress() {
    console.log(formData);
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/updateAddress";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, formData, config)
        .then((res) => {
          console.log(res);
          setisEditModeData(false);
          
          toastr.success(res.data.message)
          getDelivaryaddress();
        })
        .catch((err) => {
          console.error("Error updating address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }
  function cancleupdateaddress() {
    setisEditModeData(false);
  }

  // profile data api's

  // const profileHandle = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "email") {
  //     setEmail(value);
  //   } else if (name === "profilephone") {
  //     setProfilephone(value);
  //   }
  // };
  const profiledata = (e) => {
    e.preventDefault();

    const apiUrl = "https://aanvikshikiartapi.aanvikshiki.art/api/user/profile";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, getProfile, config)
        .then((res) => {
          console.log(res);
          toggleEditMode();
          getProfiledata();
          
          toastr.success(res.data.message)
        })
        .catch((err) => {
          console.error("Error adding address:", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };
  function getProfiledata() {
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/getProfile";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(apiUrl, config)
        .then((res) => {
          // Handle the response data as needed
          setGetProfile(res.data);
          // setProfileData(res);
        })
        .catch((err) => {
          console.error("Error fetching data ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }

  function addNewAddress() {
    setisAddNewAddress(true);
  }
  function cancleaddaddress() {
    setisAddNewAddress(false);
  }

  // show address api
  function getDelivaryaddress() {
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/getDeliveryAddress";

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(apiUrl, config)
        .then((res) => {
          setAddressData(res?.data.address);
          console.log(res?.data.address);
          if (res?.data.address.length <= 0) {
            setIsAddress(false);
          } else {
            setIsAddress(true);
          }
          // Handle the response data as needed
        })
        .catch((err) => {
          console.error("Error fetching data ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  }

  useEffect(() => {
    getDelivaryaddress();
    getProfiledata();
  }, []);

  // remove address api
  const removeAddress = (data) => {
    const userId = localStorage.getItem("userId");
  
    const body = {
      userId: userId,
      id: data._id,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/user/deleteAddress";
    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.post(apiUrl, body, config)
        .then((res) => {
          setRemoveaddress(res);
          getDelivaryaddress();
          toastr.data.success(res.data.message)
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };

  const deleteaccountdata = (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("token");
    const mobile = localStorage.getItem("mobile");

    if (window.confirm("sure to detete")) {
      const body = {
        mobile: mobile,
      };

      const apiUrl =
        "https://aanvikshikiartapi.aanvikshiki.art/api/user/deleteUser";

      // Retrieve the token from local storage

      if (authToken) {
        const config = {
          headers: {
            Authorization: authToken,
          },
        };

        Axios.post(apiUrl, body, config)
          .then((res) => {
            logOut();
          })
          .catch((err) => {
            console.error("Error delete account:", err);
            // Handle the error, display an error message, or perform other error-handling actions.
          });
      } else {
        // Handle the case where the token is not available (user is not authenticated)
      }
    }
  };
  // for logout
  function logOut() {
    localStorage.clear();
    window.location.href = "/landing";
    setAuthToken(null);
    let token = localStorage.getItem("token");

    if (token) {
      setAuthToken(token);
    } else {
    }
  }

  return (
    <>
      <div className="settings-pages">
        <div className="inside-settingsdata">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div
                  className="nav flex-column nav-pills pillsall-data"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className={`nav-link leftpills-data ${
                      activeTab === "tab1" ? "active" : ""
                    }`}
                    id="v-pills-tab1"
                    data-toggle="pill"
                    href="#tab1"
                    role="tab"
                    aria-controls="tab1"
                    aria-selected={activeTab === "tab1"}
                    onClick={() => handleTabClick("tab1")}
                  >
                    <i class="fas fa-user inside-icons"></i> Profile Details
                  </a>
                  <a
                    className={`nav-link leftpills-data ${
                      activeTab === "tab2" ? "active" : ""
                    }`}
                    id="v-pills-tab2"
                    data-toggle="pill"
                    href="#tab2"
                    role="tab"
                    aria-controls="tab2"
                    aria-selected={activeTab === "tab2"}
                    onClick={() => handleTabClick("tab2")}
                  >
                    <i className="fas fa-truck inside-icons"></i> Delivery
                    Addresses
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className={`tab-pane inside-pillscontent fade ${
                      activeTab === "tab1" ? "show active" : ""
                    }`}
                    id="tab1"
                    role="tabpanel"
                    aria-labelledby="v-pills-tab1"
                  >
                    <div className="settings-formdata">
                      <ul className="accounttitle-edit">
                        <li>
                          {" "}
                          <h3 className="settings-titles">Profile Details</h3>
                        </li>
                        {/* <li>
                          <NavLink className="edit-account-btn">Edit</NavLink>
                        </li> */}
                      </ul>
                      <div className="porfile-detailsafter">
                        <form className="account-form">
                          <ul className="profilunlisted-data">
                            <li>
                              <div className="form-group">
                                <label className="input-lables">Email</label>
                                {isEditMode ? (
                                  <input
                                    type="text"
                                    className="form-control input-content"
                                    onChange={profileHandle}
                                    value={getProfile.email}
                                    name="email"
                                  />
                                ) : (
                                  <div>{getProfile.email}</div>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="input-lables">
                                  Phone no.
                                </label>
                                {isEditMode ? (
                                  <input
                                    type="number"
                                    className="form-control input-content"
                                    onChange={profileHandle}
                                    value={getProfile.mobile}
                                    name="mobile"
                                  />
                                ) : (
                                  <div>{getProfile.mobile}</div>
                                )}
                              </div>
                            </li>
                            <li>
                              {/* <NavLink
                                className="edit-btn"
                                onClick={toggleEditMode}
                              >
                                {" "}
                                {isEditMode ? "Save" : "Edit"}
                              </NavLink> */}
                              {isEditMode ? (
                                <NavLink
                                  className="edit-btn1"
                                  onClick={profiledata}
                                >
                                  Save
                                </NavLink>
                              ) : (
                                <NavLink
                                  className="edit-btn"
                                  onClick={toggleEditMode}
                                >
                                  Edit
                                </NavLink>
                              )}
                            </li>
                          </ul>
                        </form>
                      </div>
                      <ul className="delete-account">
                        <li>
                          <h3 className="delete-account-title">
                            Delete Account
                          </h3>
                        </li>
                        <li>
                          <NavLink
                            className="delete-account-btn"
                            onClick={deleteaccountdata}
                          >
                            Delete
                          </NavLink>
                        </li>
                      </ul>
                      {/* <form className="account-form" onClick={profiledata}>
                        <div className="form-group">
                          <label className="input-lables">Email</label>
                          <input
                            type="text"
                            className="form-control input-content"
                            onChange={profileHandle}
                            value="i@gmail.co"
                            name="email"
                          />
                        </div>
                        <div className="form-group">
                          <label className="input-lables">Phone no.</label>
                          <input
                            type="number"
                            className="form-control input-content"
                            onChange={profileHandle}
                            name="profilephone"
                          />
                        </div>

                        <ul className="delete-account">
                          <li>
                            <h3 className="delete-account-title">
                              Delete Account
                            </h3>
                          </li>
                          <li>
                            <NavLink className="delete-account-btn">
                              Delete
                            </NavLink>
                          </li>
                        </ul>

                        <ul className="save-all-details">
                          <li>
                            <h3 className="details-title">Save all details</h3>
                          </li>
                          <li>
                            <NavLink className="details-btn">Save</NavLink>
                          </li>
                        </ul>
                      </form> */}
                    </div>
                  </div>
                  <div
                    className={`tab-pane inside-pillscontent fade ${
                      activeTab === "tab2" ? "show active" : ""
                    }`}
                    id="tab2"
                    role="tabpanel"
                    aria-labelledby="v-pills-tab2"
                  >
                    <div className="settings-formdata">
                      {isEditModeDate ? (
                        <form className="add-addressform-form">
                          <h3 className="address-title">UPDATE Address</h3>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control address-input-content"
                              placeholder="Name"
                              onChange={handleChanges}
                              value={formData.name}
                              name="name"
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control address-input-content"
                              placeholder="Contact No."
                              onChange={handleChanges}
                              name="mobile"
                              value={formData.mobile}
                            />
                          </div>
                          <h3 className="address-details-title">
                            Address Details
                          </h3>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control address-input-content"
                              placeholder="Pin Code"
                              onChange={handleChanges}
                              value={formData.pincode}
                              name="pincode"
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control address-input-content"
                              placeholder="Address (House no, Building, Street, Area)"
                              onChange={handleChanges}
                              value={formData.address}
                              name="address"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control address-input-content"
                              placeholder="Locality / Town"
                              onChange={handleChanges}
                              value={formData.area}
                              name="area"
                            />
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <input
                                type="text"
                                className="form-control address-input-content"
                                placeholder="City / District"
                                onChange={handleChanges}
                                value={formData.city}
                                name="city"
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <input
                                type="text"
                                className="form-control address-input-content"
                                placeholder="State"
                                onChange={handleChanges}
                                value={formData.state}
                                name="state"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                className="form-check-input address-input-content"
                                type="checkbox"
                                id="gridCheck"
                              />
                              <label
                                className="form-check-label address-lable-content"
                                htmlFor="gridCheck"
                              >
                                Set as default delivery address
                              </label>
                            </div>
                          </div>
                          <NavLink
                            onClick={updateAddress}
                            className="address-btn"
                          >
                            UPDATE ADDRESS
                          </NavLink>
                          <NavLink
                            className="cancleaddress-btn"
                            onClick={cancleupdateaddress}
                          >
                            Cancle
                          </NavLink>
                        </form>
                      ) : (
                        <div>
                          <div className="settings-formdata">
                            {isAddNewAddress === false ? (
                              <div>
                                <ul className="accounttitle-edit">
                                  <li>
                                    {" "}
                                    <h3 className="settings-titles">
                                      Saved Delivery Addresses
                                    </h3>
                                  </li>
                                </ul>

                                {isAddress === true ? (
                                  addressData.map((address, index) => (
                                    <div
                                      className="main-addresscarddata"
                                      key={index}
                                    >
                                      <div className="left-side">
                                        <div className="selection-button">
                                          <input
                                            className="form-check-input checkBox-inside"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                          />
                                        </div>
                                        <div className="left-adress-inside">
                                          <div className="topAdress-rightBtns d-flex justify-content-between">
                                            <div className="name-edit">
                                              {address.name}
                                            </div>
                                            <div className="right-side">
                                              <NavLink
                                                className="action-button remove-button"
                                                onClick={() =>
                                                  removeAddress(address)
                                                }
                                              >
                                                Remove
                                              </NavLink>
                                              <button
                                                onClick={() =>
                                                  editAddress(address)
                                                }
                                                className="action-button edit-button"
                                              >
                                                Edit
                                              </button>
                                            </div>
                                          </div>
                                          <div className="address">
                                            {address.address}
                                          </div>
                                          <div className="address">
                                            {address.city} {""}
                                            {address.pincode}
                                          </div>
                                          <div className="mobile">
                                            Mobile no.{" "}
                                            <span className="number-ColorChange">
                                              {address.mobile}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : isAddress === false ? (
                                  <div className="donthave-address">
                                    <p className="dont-para">
                                      You currently don't have any saved
                                      delivery addresses. Add an address here to
                                      be pre-filled for quicker checkout.
                                    </p>
                                  </div>
                                ) : null}

                                <NavLink
                                  onClick={addNewAddress}
                                  className="add-address-btn"
                                >
                                  Add Address
                                </NavLink>
                              </div>
                            ) : isAddNewAddress === true ? (
                              <form className="add-addressform-form">
                                <h3 className="address-title">Add Address</h3>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control address-input-content"
                                    placeholder="Name"
                                    onChange={handleChange}
                                    name="name"
                                  />
                                </div>

                                <div className="form-group">
                                  <input
                                    type="number"
                                    className="form-control address-input-content"
                                    placeholder="Contact No."
                                    onChange={handleChange}
                                    name="phoneNumber"
                                  />
                                </div>
                                <h3 className="address-details-title">
                                  Address Details
                                </h3>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control address-input-content"
                                    placeholder="Pin Code"
                                    onChange={handleChange}
                                    name="pincode"
                                  />
                                </div>

                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control address-input-content"
                                    placeholder="Address (House no, Building, Street, Area)"
                                    onChange={handleChange}
                                    name="address"
                                  />
                                </div>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control address-input-content"
                                    placeholder="Locality / Town"
                                    onChange={handleChange}
                                    name="area"
                                  />
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <input
                                      type="text"
                                      className="form-control address-input-content"
                                      placeholder="City / District"
                                      onChange={handleChange}
                                      name="city"
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <input
                                      type="text"
                                      className="form-control address-input-content"
                                      placeholder="State"
                                      onChange={handleChange}
                                      name="state"
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input address-input-content"
                                      type="checkbox"
                                      id="gridCheck"
                                    />
                                    <label
                                      className="form-check-label address-lable-content"
                                      htmlFor="gridCheck"
                                    >
                                      Set as default delivery address
                                    </label>
                                  </div>
                                </div>
                                <NavLink
                                  className="address-btn"
                                  onClick={addAddress}
                                >
                                  ADD ADDRESS
                                </NavLink>
                                <NavLink
                                  className="cancleaddress-btn"
                                  onClick={cancleaddaddress}
                                >
                                  Cancle
                                </NavLink>
                              </form>
                            ) : null}

                            {/* if you want add address show this */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
